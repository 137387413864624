import { action, makeObservable, observable, onBecomeObserved, runInAction } from 'mobx';
import { RootStore } from './RootStore';
import { User } from 'types/User';
import {
  identifyUser,
  optInTracking,
  signOutUser,
} from 'utils/analytics/analytics';
import mixpanel from 'mixpanel-browser';
import { getCookie, removeCookie } from 'utils/cookie';
import {
  k_UPGRADE_POPUP_ACTIVE,
  k_UPGRADE_POPUP_LAST_DISMISSED,
  k_UPGRADE_POPUP_TIMER_START_KEY,
} from 'components/UpgradePopup/UpgradePopup';
import { getCountry, getMe } from 'utils/api/auth';
import { getTokenBalance } from 'utils/api/tokens';
import {
  getActiveSubscription,
  getMostRecentSubscription,
  getSubscriptions,
  userIsEligibleForFirstEntryFreeDeal,
} from 'utils/api/subscriptions';
import {
  eligibleForUpgradeIncentive,
  playerHasActiveRecurringSubscriptionDiscount,
} from 'utils/api/bonus';
import { RecurringSubscriptionDiscount } from 'types/RecurringSubscriptionDiscount';
import { ISubscription, SubscriptionCollection } from 'types/Subscription';
import { Prize, WishlistPrize } from 'types/Prize';
import { convertImageToBlob } from 'utils/image/convertImageToBlob';
import {
  changeUsername,
  removePhoto,
  uploadPhoto,
} from 'utils/api/leaderboard';
import { ITicket } from 'types/Ticket';
import { submitQuestion } from 'utils/api/feedback';
import { fetchFavouritedPrizes, toggleFavouritedPrize } from 'utils/api/prizes';
import { getTicketsWithEntries } from 'utils/api/tickets';
import { inviteUrl } from 'utils/common';

export type UserHydration = {
  currentUser?: User | null;
  currentGuestUser?: User | null;
  wishListPrizes?: WishlistPrize[] | null;
};

export class UserStore {
  root: RootStore;
  initialized: boolean = false;
  currentUser: User | null = null;
  currentGuestUser: User | null = null;
  eligibleForIntroOffer: boolean = true;
  eligibleForUpgrade: boolean = true;
  recurringSubscriptionDiscount: RecurringSubscriptionDiscount = {
    rewardCode: '',
    rewardValuePerStep: 0,
    discountedWeeksRemaining: 0,
  };
  subscriptions: SubscriptionCollection | null = null; // ACTIVE
  wishListPrizes: WishlistPrize[] | null = null;
  upcomingDrawTickets: ITicket[] | null = null;

  impactShareOptions: null | Record<string, { href: string }> = null;

  outsideUK: boolean = false;

  constructor(root: RootStore) {
    this.root = root;
    this.initializeCurrentUser();

    makeObservable(this, {
      setCurrentUser: action,
      setCurrentGuestUser: action,
      logout: action,
      initializeCurrentUser: action,
      setTokenBalance: action,
      setTreesPlanted: action,
      setLeaderboardStats: action,
      updateInfoOfferEligibility: action,
      updateUpgradeEligibility: action,
      fetchRecurringSubscriptionDiscount: action,
      setActiveSubscription: action,
      hydrate: action,
      setLeaderboardPicture: action,
      toggleWishlistPrize: action,
      submitHelpQuestion: action,
      submitAccountDetails: action,

      checkCountry: action,
      initialized: observable,
      wishListPrizes: observable,
      impactShareOptions: observable,
      currentUser: observable,
      currentGuestUser: observable,
      eligibleForIntroOffer: observable,
      eligibleForUpgrade: observable,
      recurringSubscriptionDiscount: observable,
      subscriptions: observable,
      upcomingDrawTickets: observable,
      outsideUK: observable,
    });

    this.checkCountry();
  }

  checkCountry = async () => {
    const result = await getCountry();
    this.outsideUK = result.country !== 'unknown' && result.country !== 'GB';
  };

  setActiveSubscription = async () => {
    if (!this.currentUser) {
      return;
    }
    const jwt = getCookie('jwt');
    const fetchActiveSubscriptionResult = await getSubscriptions(jwt);

    if (fetchActiveSubscriptionResult) {
      this.subscriptions = fetchActiveSubscriptionResult;
    }
  };

  initializeCurrentUser = async () => {
    if (typeof window === 'undefined') {
      return;
    }
    const jwt = getCookie('jwt');
    const guestJwt = getCookie('guest_jwt');
    const user = await getMe(jwt);

    this.setCurrentUser(user);

    if (!this.currentUser) {
      const guestJwt = getCookie('guest_jwt');
      this.setCurrentGuestUser(await getMe(guestJwt));
    }

    await this.fetchUpcomingDrawTickets();
    await this.fetchWishListPrizes();

    this.setImpactShareOptions();
    this.initialized = true;
  };

  updateTokenBalance = async () => {
    const jwt = getCookie('jwt');
    const result = await getTokenBalance(jwt);
    if (result) {
      this.setTokenBalance(result.balance);
    }
  };

  setImpactShareOptions = () => {
    if (!this.currentUser) return;

    const referralLink = inviteUrl(this.currentUser.referral_id.toString())
    const impactText = `I've planted ${this.currentUser.trees_planted === 1 ? '1 tree' : `${this.currentUser.trees_planted.toLocaleString()} trees`} with DAYMADE!`;

    this.impactShareOptions = {
      facebook: {
        href: `https://www.facebook.com/dialog/share?app_id=256975402191022&display=popup&href=${encodeURIComponent(referralLink)}&quote=${impactText}`,
      },
      whatsapp: {
        href: `whatsapp://send?text=${encodeURIComponent(`${impactText} ${referralLink}`)}`,
      },
      twitter: {
        href: `https://twitter.com/intent/tweet/?url=${encodeURIComponent(referralLink)}&text=${encodeURIComponent(impactText)}`,
      },
      email: {
        href: `mailto:?subject=${encodeURIComponent(
          'Check Out DAYMADE!'
        )}&body=${encodeURIComponent(`${impactText} ${referralLink}`)}`,
      },
      linkedin: {
        href: `https://www.linkedin.com/shareArticle?mini=true&title=${encodeURIComponent(impactText)}&url=${encodeURIComponent(referralLink)}`,
      },
    };
  };

  updateCurrentUser = async () => {
    await this.initializeCurrentUser();
  };

  setCurrentUser = (user: User | null) => {
    const userChanged = this.currentUser?.id !== user?.id;

    this.currentUser = user?.id ? user : null;

    if (user) {
      optInTracking();
      if (typeof window === 'undefined') {
        return;
      }

      mixpanel.register({
        name: user.first_name + ' ' + user.last_name,
        email: user.email_address,
      });

      identifyUser({
        emailAddress: user?.email_address,
        id: user?.id as any,
        firstName: user?.first_name,
        lastName: user?.last_name,
      });

      this.setActiveSubscription();
    }

    if (userChanged) {
      this.updateInfoOfferEligibility();
      this.updateUpgradeEligibility();
      this.fetchRecurringSubscriptionDiscount();
    }

    this.setActiveSubscription();
  };

  setCurrentGuestUser = (user: User) => {
    this.currentGuestUser = user;
  };

  setTokenBalance = (balance: number) => {
    this.currentUser && (this.currentUser.tokenBalance = balance);
  };

  setTreesPlanted = (treesPlanted: number) => {
    this.currentUser && (this.currentUser.trees_planted = treesPlanted);
  };

  setLeaderboardStats = (stats: { points: number; position: number }) => {
    this.currentUser && (this.currentUser.leaderboardStats = stats);
  };

  setLeaderboardPicture = async (fileObject: any) => {
    if (!this.currentUser) {
      return;
    }
    const jwt = getCookie('jwt');

    if (!['image/png', 'image/jpeg', 'image/jpg'].includes(fileObject.type)) {
      this.root.UIStore.displayToast({
        title:
          'Error: Unsupported File Type. Please upload only image files in one of the following formats: JPEG or PNG.',
        color: 'red',
        timeout: 5000,
      });
      return;
    }

    const file = convertImageToBlob(fileObject);

    if (!file) return;

    const result = await uploadPhoto(jwt, file);

    if (result?.successful) {
      const user = this.currentUser;
      user.leaderboard_photo_location = result.location;
      this.setCurrentUser(user);
      this.root.UIStore.displayToast({
        title: 'Profile photo updated!',
        color: 'green',
        timeout: 3000,
      });
      return;
    } else {
      this.root.UIStore.displayToast({
        title: result.reason,
        color: 'red',
        timeout: 3000,
      });
      return;
    }
  };

  submitAccountDetails = async (
    file: any,
    leaderboardName: string,
    removedPicture: boolean
  ) => {
    const jwt = getCookie('jwt', null);

    if (
      leaderboardName.localeCompare(
        this.currentUser?.leaderboard_username ?? ''
      )
    ) {
      const usernameChangeResult = await changeUsername(jwt, leaderboardName);

      if (usernameChangeResult.successful === false) {
        this.root.UIStore.displayToast({
          title: usernameChangeResult.reason,
          color: 'red',
          timeout: 3000,
        });
        return;
      }
    }

    if (file) {
      this.setLeaderboardPicture(file);
    }

    if (!file && removedPicture) {
      await removePhoto(jwt);

      this.root.UIStore.displayToast({
        title: 'Successfully updated details.',
        timeout: 3500,
      });
    }

    this.updateCurrentUser();
  };

  setWishlistPrizes = action((wishlistPrizes: WishlistPrize[]) => {
    localStorage.removeItem('wishListPrizes');
    this.wishListPrizes = wishlistPrizes;
  });

  fetchWishListPrizes = async () => {
    if (this.currentUser === null) return;
    const cacheKey = 'wishListPrizes';
    const ttl = 60 * 60 * 1000; // 1 hour
    const now = new Date().getTime();

    // Check if data exists and is still valid
    const cachedData = localStorage.getItem(cacheKey);
    if (cachedData) {
      const { data, expiration } = JSON.parse(cachedData);
      if (now < expiration) {
        this.setWishlistPrizes(data);
        return;
      }
    }

    // Proceed to fetch if no valid cache is found
    if (
      (this.wishListPrizes !== null && this.wishListPrizes.length > 0) ||
      typeof window === 'undefined'
    ) {
      return;
    }
    const jwt = getCookie('jwt');
    const result = await fetchFavouritedPrizes(jwt);

    if (result.auth !== undefined) {
      this.setWishlistPrizes([]);
    } else {
      this.setWishlistPrizes(result);
    }

    // Save fetched data to localStorage with a new expiration
    const newCache = {
      data: result,
      expiration: now + ttl,
    };
    localStorage.setItem(cacheKey, JSON.stringify(newCache));
  };

  toggleWishlistPrize = async (prizeInstanceId: number, toggle: boolean) => {
    const jwt = getCookie('jwt');
    const result = await toggleFavouritedPrize(jwt, prizeInstanceId, toggle);

    if (result.successful) {
      this.root.UIStore.displayToast({
        title: toggle
          ? 'Added prize to wishlist'
          : 'Removed prize from wishlist',
        color: 'green',
        timeout: 3000,
      });

      if (!toggle) {
        // If the item is being removed, update the local list
        runInAction(() => {
          this.setWishlistPrizes(
            this.wishListPrizes!.filter(
              (prize) => prize.prizeInstanceId !== prizeInstanceId
            )
          );
        });
      }

      // Invalidate cache by removing the localStorage entry
      localStorage.removeItem('wishListPrizes');
    } else {
      this.root.UIStore.displayToast({
        title: toggle
          ? 'Unable to add prize to wishlist. Please try again later.'
          : 'Unable to remove prize from wishlist. Please try again later.',
        color: 'red',
        timeout: 3000,
      });
    }
  };

  fetchUpcomingDrawTickets = async () => {
    if (this.upcomingDrawTickets !== null) {
      return;
    }
    const jwt = getCookie('jwt');

    if (!jwt) {
      return;
    }
    let ticketsWithEntriesForScheduledDraws = (await getTicketsWithEntries(
      jwt
    )) as ITicket[];

    const latestDrawId = ticketsWithEntriesForScheduledDraws.sort(
      (a, b) => Number(b.draw_id) - Number(a.draw_id)
    )[0].draw_id as unknown as Number;

    this.setUpcomingDrawTickets(
      ticketsWithEntriesForScheduledDraws
        .sort(
          (a, b) =>
            new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        )
        .filter((ticket: ITicket) => Number(ticket.draw_id) === latestDrawId)
    );
  };

  setUpcomingDrawTickets = async (tickets: ITicket[]) => {
    this.upcomingDrawTickets = tickets;
  };

  updateInfoOfferEligibility = async () => {
    const jwt = getCookie('jwt');

    const introOfferResult = await userIsEligibleForFirstEntryFreeDeal(jwt);
    this.eligibleForIntroOffer = !(introOfferResult?.eligible === false);
  };

  updateUpgradeEligibility = async () => {
    const jwt = getCookie('jwt');

    const introOfferResult = await eligibleForUpgradeIncentive(jwt);
    this.eligibleForUpgrade = !(introOfferResult?.eligible === false);
  };

  fetchRecurringSubscriptionDiscount = async () => {
    const jwt = getCookie('jwt');

    const res = await playerHasActiveRecurringSubscriptionDiscount(jwt);
    if (res.hasDiscount) {
      this.recurringSubscriptionDiscount = {
        rewardCode: res.rewardCode,
        rewardValuePerStep: res.rewardValuePerStep,
        discountedWeeksRemaining: res.remainingWeeks,
      };
    }
  };

  submitHelpQuestion = async (question: string) => {
    if (!this.currentUser) return;
    const res = await submitQuestion(this.currentUser.email_address, question);
    if (res.successful === true) {
      this.root.UIStore.displayToast({
        title:
          "Thanks for your question! We'll get back to you using your email address.",
        color: 'green',
        timeout: 3000,
      });
      return;
    } else {
      this.root.UIStore.displayToast({
        title: 'Error: Cannot send question. Please try again later.',
        color: 'red',
        timeout: 3000,
      });
    }
  };

  logout = () => {
    removeCookie('jwt');
    localStorage.removeItem(k_UPGRADE_POPUP_TIMER_START_KEY);
    localStorage.removeItem(k_UPGRADE_POPUP_ACTIVE);
    localStorage.removeItem(k_UPGRADE_POPUP_LAST_DISMISSED);

    this.clearUserData();
    signOutUser();
  };

  clearUserData = () => {
    this.setCurrentUser(null);
    this.upcomingDrawTickets = null;
    this.subscriptions = null;
    this.wishListPrizes = null;
  };

  hydrate(data?: UserHydration) {
    if (data?.currentUser && data.currentUser.id) {
      this.currentUser = data.currentUser;
    }
    if (data?.currentGuestUser) {
      this.currentGuestUser = data.currentGuestUser;
    }

    if (data?.wishListPrizes) {
      this.wishListPrizes = data.wishListPrizes;
    }

    this.initialized = true;
  }
}
