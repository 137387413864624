import ProfilePhoto from "components/ProfilePhoto";
import Link from "next/link";
import { User } from "types/User";
import SnoozeSwitchSection from "../SnoozeSwitchSection";
import { SnoozeSwitchMoon } from "../SnoozeSwitchMoon";


export const AccountHeaderSection = ({
  currentUser,
  welcomeBackPlanName,
}: {
  currentUser: User;
  welcomeBackPlanName: string | null
}) => {
  return (
    <div className="flexRow fullWidth">
      <Link href="/account">
        <a style={{ width: 'auto' }}>
          <ProfilePhoto disableDetailsPopup={true} scale={0.8} />
        </a>
      </Link>

      <div
        className="flexLeftAligned fullWidth"
        style={{ margin: '1em 0 1em 1em' }}
      >
        <p
          style={{
            fontStyle: 'italic',
            margin: 0,
            fontSize: '0.9em',
          }}
        >
          {currentUser.first_name}, welcome back to
        </p>
        <p style={{ margin: '0.5em 0 0' }}>
          <span className="spacedFont" style={{ fontSize: '1.1em' }}>
            DAY<strong>MADE</strong>
          </span>
          {welcomeBackPlanName && (
            <>
              <span
                style={{
                  fontSize: '1.5em',
                  fontWeight: 500,
                  margin: '0 0.3em 0 0.1em',
                }}
              >
                |
              </span>
              <span
                style={{
                  fontSize: '1.1em',
                  fontWeight: 500,
                }}
              >
                {welcomeBackPlanName}
              </span>
            </>
          )}
        </p>
        <SnoozeSwitchSection
          style={undefined}
          SwitchComponent={SnoozeSwitchMoon}
        />
      </div>
      <style>
        {`
          .activeSide,
          .snoozedSide {
            display: flex;
            align-items: center;
            margin-top: 0.7em;
          }

          .heading {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 0.8rem;
            margin-bottom: 0;
          }

          .subheading {
            font-size: 0.6rem;
            font-style: italic;
          }
        `}
      </style>
    </div>
  );
};