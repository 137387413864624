import Link from 'next/link';
import React, { useEffect } from 'react';
import { ASSET_PREFIX, colors } from 'utils/constants';
import {
  track_links,
} from 'utils/analytics/analytics';
import { FooterExternal, FooterLink } from './FooterLink';
import { SignupNewsletter } from './SignupNewsletter';
import { SocialLinkSection } from './SocialLinkSection';
import { PaymentLogoSection } from './PaymentLogoSection';
import { TrustIconSection } from './TrustIconSection';

const Footer = () => {

  useEffect(() => {
    track_links('#facebookext', 'Clicked Facebook link');
    track_links('#instagramext', 'Clicked Instagram link');
    track_links('#youtubeext', 'Clicked Youtube link');
    track_links('#twitterext', 'Clicked Twitter link');
    track_links('#tiktokext', 'Clicked Tiktok link');
    track_links('#faqlink', 'Clicked FAQ link');
    track_links('#contactuslink', 'Clicked Contact us link');
  }, []);

  return (
    <footer
      className="w-full p-5 flex justify-center"
      style={{ backgroundColor: colors.footerBackground }}
    >
      <div className="w-full max-w-[1200px] grid grid-cols-1 gap-8 my-4">
        <div className=" w-full grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-0">
          <div className="w-full flex flex-col justify-center items-center md:items-start ">
            <Link href="/">
              <img
                className="cursor-pointer w-[150px] md:w-[200px]"
                src={`${ASSET_PREFIX}/images/daymade-logo.png`}
                alt="DAYMADE logo"
              />
            </Link>
          </div>
          <div className="flex">
            <SocialLinkSection />
          </div>
          <div className="flex justify-center md:justify-end">
            <PaymentLogoSection />
          </div>
        </div>

        <div className="separator !m-0 !w-full" />

        <div className=" w-full h-full grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-0">
          <div className="w-full flex flex-col justify-between md:items-start gap-4">
            <FooterLink link={'/about-us'} text={'About Us'} />
            <FooterLink link={'/how-it-works'} text={'How It Works'} />
            <FooterExternal
              text={'FAQ'}
              link={'https://daymade.zendesk.com/hc/en-gb'}
              id={'faqlink'}
            />
            <FooterLink link={'/gift-cards'} text={'Gift Cards'} />
          </div>
          <div className="w-full flex flex-col justify-between md:items-start gap-4 ">
            <FooterLink link={'/blog'} text={'Blog'} />

            <FooterLink
              link={'/terms-and-conditions'}
              text={'Terms & Conditions'}
            />
            <FooterLink link={'/privacy-policy'} text={'Privacy Policy'} />
            <FooterLink link={'/contact-us'} text={'Contact Us'} />
            
          </div>
          <div className="hidden md:flex justify-center md:justify-end">
            <SignupNewsletter />
          </div>
        </div>
        <div className="flex md:hidden separator !m-0 !w-full" />

        <div className="flex md:hidden justify-center">
          <SignupNewsletter />
        </div>

        <div className="separator !m-0 !w-full" />

        <div className="w-full grid grid-cols-1">
          <TrustIconSection />
        </div>

        <div className="separator !m-0 !w-full" />

        <div className="w-full  grid grid-cols-1">
          <div
            data-nosnippet
            style={{ fontWeight: 600, textAlign: 'center' }}
            className="text-centered"
          >
            DAYMADE is an associated brand of, and operated by, DAYMADE LTD
            (registered in England & Wales, company number 12333770).
            <br />
            <br />
            Please note that DAYMADE is an operator of prize draws. Our online
            contests to win prizes include a method of entry via post. No
            purchase is necessary to enter but the intention of the draws is
            that people enter to win prizes and support a worthy charitable
            cause.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
