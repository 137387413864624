import React, { useEffect, useRef } from 'react';
import {
  trackEecCheckoutOption,
} from 'utils/analytics/analytics';
import AdyenDropInCheckout from './AdyenDropInCheckout';
import { SOURCE_QUERY_PARAM } from 'pages/validate-3ds-payment';
import { observer } from 'mobx-react-lite';
import { useEnterStore } from 'hooks/stores/useEnterStore';
import { useRouter } from 'next/router';
import { useUserStore } from 'hooks/stores/useUserStore';
import PlainLoadingSpinner from 'components/PlainLoadingSpinner';
import { Message } from 'semantic-ui-react';

interface IPlasmicEnterCheckoutProps {}

export const PlasmicEnterCheckout = observer(
  ({}: IPlasmicEnterCheckoutProps) => {
    const router = useRouter();

    const {
      confirmed,
      upgradePageSource,
      orderId,
      adyenPaymentSession,
      isValid,
      isReferralFlow,
      orderError,
      setOrderError,
      haveValidAdyenSession,
      handleContinueToPayment,
      navigateToCheckout,
      checkOrderStatusAndAdvanceToConfirmation,
    } = useEnterStore();

    const { currentUser, initialized: userStoreInitialised } = useUserStore();

    const orderIdRef = useRef<string | null>(orderId);
    const orderCompletionTimerRef = useRef<any>(null);

    useEffect(() => {
      return () => {
        if (orderCompletionTimerRef.current) {
          clearTimeout(orderCompletionTimerRef.current);
        }
      };
    }, []);

    useEffect(() => {
      if (haveValidAdyenSession && !orderCompletionTimerRef.current) {
        // Start periodically checking whether order is complete.
        clearTimeout(orderCompletionTimerRef.current);
        checkOrderStatusAndAdvanceToConfirmation(
          orderIdRef,
          orderCompletionTimerRef
        );
      }
    }, [haveValidAdyenSession, orderIdRef.current]);

    useEffect(() => {

      (async() => {
        if (!isValid) {
          router.replace(isReferralFlow ? '/enter-now' : '/enternow/1');
        } 
        else {
          if (userStoreInitialised && !currentUser) {
            await navigateToCheckout(isReferralFlow);
          }
        }
      })()

    }, [isValid, userStoreInitialised, currentUser]);

  
    useEffect(() => {
      if (!haveValidAdyenSession && isValid && currentUser) {
        handleContinueToPayment(orderIdRef);
      }
    }, [haveValidAdyenSession, isValid, currentUser]);

    if (!isValid) {
      return <Message error>Order not valid</Message>;
    }
    if (!confirmed) {
      return <Message error>Order not confirmed</Message>;
    }

    if (!haveValidAdyenSession) {
      return <PlainLoadingSpinner style={{ margin: '2em auto' }} />;
    }

    return (
      <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        {orderError && orderError.length > 0 && (
          <div
            className="paddedMaxWidthContainer"
            style={{ margin: '0 auto' }}
          >
            <>
              <div style={{ height: '20px' }} />
              <Message
                id="errorMessage"
                negative
                style={{ width: '100%', maxWidth: '500px ' }}
              >
                {orderError}
              </Message>
            </>
          </div>
        )}
        <AdyenDropInCheckout
          queryParamsObject={{
            source: upgradePageSource
              ? SOURCE_QUERY_PARAM.UPGRADE
              : SOURCE_QUERY_PARAM.STANDARD_TICKET_ORDER,
          }}
          paymentSession={adyenPaymentSession}
          onLoadingStateChange={(loading: boolean) => {
            // Handle loading state if needed
          }}
          completionHandler={async () => {
            await trackEecCheckoutOption('Card');
          }}
          displayError={async (error: string) => {
            setOrderError(error);
          }}
          style={{
            margin: '2em auto',
            fontSize: '12px',
            width: '100%',
            maxWidth: 500,
          }}
          initialRedirectResult={undefined}
        />
      </div>
    );
  }
);
