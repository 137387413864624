import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import {
  ASSET_PREFIX,
  colors,
  Cta,
  SubscriberStatus,
  useChristmasLogos,
  useHalloweenLogos,
} from 'utils/constants';
import RoundedButton, { ColorStyle } from '../../RoundedButton';
import HeaderBanner from 'components/layout/header/HeaderBanner';
import $ from 'jquery';
import GlowBar from 'components/GlowBar';
import Router, { useRouter } from 'next/router';

import { getCta, getSubscriberStatus, scrollToSection } from 'utils/common';
import Separator from 'components/Separator';
import 'node_modules/react-image-gallery/styles/css/image-gallery.css';
import MainBurgerMenuContent from 'components/layout/header/main-burger-menu-content/MainBurgerMenuContent';
import PrizesBurgerMenuContent from 'components/layout/header/main-burger-menu-content/PrizesBurgerMenuContent';
import SubcategoriesBurgerMenuContent from 'components/layout/header/main-burger-menu-content/SubcategoriesBurgerMenuContent';
import PrizeFilterOptions from 'prizes/weekly-draw/PrizeFilterOptions';
// import SearchPanel from 'components/layout/header/SearchPanel/SearchPanel';
import { HeaderIconWithBadge } from './HeaderIconWithBadge';
import { AccountHeaderIcon } from './AccountHeaderIcon';
import { trackEvent } from 'utils/analytics/analytics';
import { useUserStore } from 'hooks/stores/useUserStore';
import { useUIStore } from 'hooks/stores/useUIStore';
import { useDrawStore } from 'hooks/stores/useDrawStore';
import { observer } from 'mobx-react-lite';
import { PlasmicComponent } from '@plasmicapp/loader-nextjs';
import { MOBILE_WIDTH, useIsMobile } from 'hooks/ui/useIsMobile';
import { AccountMenu } from '../account/AccountMenu';
import { ScrollableComponent } from '../ScrollableComponent';

const SocialMediaLinks = () => (
  <div
    className="socialMediaLinks"
    style={{ margin: '2em auto 1em', textAlign: 'center' }}
  >
    <a
      href="https://www.facebook.com/daymadeuk/"
      target="_blank"
      rel="noreferrer"
    >
      <img
        src={`${ASSET_PREFIX}/images/social-media-icons/facebook-circle.svg`}
        alt="Facebook"
      />
    </a>

    <a
      href="https://www.instagram.com/daymadeuk/"
      target="_blank"
      rel="noreferrer"
    >
      <img
        src={`${ASSET_PREFIX}/images/social-media-icons/instagram-circle.svg`}
        alt="Instagram"
      />
    </a>

    <a
      href="https://www.youtube.com/channel/UCUVOk8dVM_mu-1qgKwtn9HA"
      target="_blank"
      rel="noreferrer"
    >
      <img
        src={`${ASSET_PREFIX}/images/social-media-icons/youtube-circle.svg`}
        alt="YouTube"
      />
    </a>

    <a
      href="https://twitter.com/DAYMADEUK"
      target="_blank"
      rel="noreferrer"
    >
      <img
        src={`${ASSET_PREFIX}/images/social-media-icons/twitter-circle.svg`}
        alt="Twitter"
      />
    </a>

    <a
      href="https://vm.tiktok.com/ZMeakA3Be/"
      target="_blank"
      rel="noreferrer"
    >
      <img
        src={`${ASSET_PREFIX}/images/social-media-icons/tiktok-circle.svg`}
        alt="TikTok"
      />
    </a>

    <style>
      {`
        .socialMediaLinks img {
          width: 28px;
          height: 28px;
          margin: 0 0.5em;
        }
      `}
    </style>
  </div>
);

const ExpandableMenu = {
  MAIN_BURGER: 0,
  ACCOUNT: 1,
  PRIZES: 2,
  GAMES: 3,
  ABOUT_US: 4,
  SEARCH: 5,
};

export const BurgerMenuContent = {
  MAIN_MENU: 0,
  ALL_PRIZES: 1,
  TRIPS_AND_STAYS: 2,
  EXPERIENCES: 3,
  LIFESTYLE: 4,
  TECH: 5,
  FAMILY: 6,
  ABOUT_US: 7,
};

const HeaderStyle = {
  ORIGINAL: 0,
  STRONGER: 1,
};

const enterCtaHandlerForPathname = (pathname) => {
  if (pathname.startsWith('/iceland-raffle')) {
    return null;
  } else if (pathname === '/') {
    return () => {
      if ($('#entryMethodSelectionSection').length > 0) {
        scrollToSection('#entryMethodSelectionSection');
      } else {
        Router.push(getCta().url);
      }
    };
  } else {
    return () => Router.push(getCta().url); // Weekly Draw default
  }
};

const TokenTownHeaderIcon = observer(({ white }) => {
  const { currentUser } = useUserStore();
  if (!currentUser) {
    return null;
  }

  return (
    <div className="hideOnVerySmallScreens">
      <Link href="/token-town">
        <a>
          <HeaderIconWithBadge
            iconImage={`${ASSET_PREFIX}/images/header/token-town-icon${
              white ? '-white' : ''
            }.svg`}
            iconImageAlt="Token Town icon"
            badgeNumber={currentUser.tokenBalance}
          />
        </a>
      </Link>
      <style jsx>
        {`
          .hideOnVerySmallScreens {
            display: none;
          }

          @media (min-width: 365px) {
            .hideOnVerySmallScreens {
              display: inline-block;
            }
          }
        `}
      </style>
    </div>
  );
});

const Header = observer(({showDrawCounter = false}) => {
  const { displayToast, headerBanner } = useUIStore();
  const { upcomingDrawDate } = useDrawStore();

  const { currentUser, updateCurrentUser, logout, upcomingDrawTickets } = useUserStore();
  const router = useRouter();

  const { isMobile } = useIsMobile();
  const [isChristmas] = useState(useChristmasLogos());

  const adminPage = router.pathname.startsWith('/admin');
  const icelandRafflePage = router.pathname.startsWith('/iceland-raffle');
  const enterPage = router.pathname.startsWith('/enter');
  const dailyPoundRafflePage = router.pathname.startsWith(
    '/token-town/pound-raffle'
  );
  const upgradePage = router.pathname.startsWith(
    '/account/subscriptions/upgrade'
  );
  const confirmationPage = router.pathname.includes('confirmation');
  const isPlasmic = router.pathname.includes('/[[...catchall]]');
  const showAnnouncementsBanner = false;
  // (!adminPage && !icelandRafflePage && !dailyPoundRafflePage) ||
  // headerBanner; /* keep announcements bar if header banner (e.g. referral message) */

  const HEADER_HEIGHT = `${(isPlasmic ? 84 : 76) + (headerBanner ? 69 : 0)}px`; // Hardcoded to avoid janking on page load when we dynamically push content down.

  const challengerPage = router.pathname.startsWith('/free-daily-draw');
  const tokenTownPage = router.pathname.includes('/token-town');
  const christmasTravelRafflePage = router.pathname.startsWith(
    '/christmas-travel-raffle'
  );

  const showDesktopCTA =
    !upgradePage &&
    !icelandRafflePage &&
    !confirmationPage &&
    !tokenTownPage &&
    !christmasTravelRafflePage;

  const loggedIn = currentUser && currentUser.id;
  const [previouslyLoggedIn, setPreviouslyLoggedIn] = useState(true);

  const [expandedMenu, setExpandedMenu] = useState(null);
  const menuOpen = expandedMenu !== null;
  const [hoveringOverHeader, setHoveringOverHeader] = useState(false);

  const [selectedMainBurgerMenuContent, setSelectedMainBurgerMenuContent] =
    useState(BurgerMenuContent.MAIN_MENU);

  const showHeaderCTA =
    !enterPage &&
    !tokenTownPage &&
    !upgradePage &&
    !icelandRafflePage &&
    !confirmationPage &&
    !expandedMenu &&
    !christmasTravelRafflePage &&
    (!loggedIn ||
      getSubscriberStatus(currentUser) !== SubscriberStatus.PREMIUM);

  const welcomeBackPlanName =
    currentUser?.subscriberStatus === 'PREMIUM'
      ? 'Premium'
      : currentUser?.subscriberStatus === 'LITE'
      ? 'Lite'
      : currentUser?.subscriberStatus === 'FREEMIUM'
      ? 'Free'
      : null;

  const enterCtaHandler = enterCtaHandlerForPathname(router.pathname);
  const enterCtaColorStyle = icelandRafflePage
    ? ColorStyle.Iceland
    : ColorStyle.Primary;
  const defaultCTA = currentUser?.subscriberStatus
    ? Cta[currentUser.subscriberStatus]
    : Cta.NON_SUBSCRIBER;
  const homePage = router.pathname === '/[[...catchall]]';
  const useDefaultCTA =
    !router.pathname.startsWith('/token-town/pound-raffle') &&
    !router.pathname.startsWith('/token-town/free-daily-draw') &&
    !router.pathname.startsWith('/iceland-raffle') &&
    !(homePage && !currentUser?.subscriberStatus);

  const simplifiedDesktopHeader = enterPage || homePage;

  const headerStyle = HeaderStyle.ORIGINAL;
  const strongerHeaderStyle = headerStyle === HeaderStyle.STRONGER;
  const [isHeaderTransparent, setIsHeaderTransparent] =
    useState(strongerHeaderStyle); // || router.asPath === '/test/home-dashboard'
  const HOME_PAGE_BREAK_POINT = 990;

  const handleDismissExpandedMenu = () => {
    setExpandedMenu(null);
    setSelectedMainBurgerMenuContent(BurgerMenuContent.MAIN_MENU); // Reset to main menu content if burger menu is closed;
  };

  const handleExpandMenu = (menu) => {
    if (expandedMenu === menu) {
      // Deselect
      handleDismissExpandedMenu();
      trackEvent('Popover Closed');
    } else {
      setExpandedMenu(menu);

      const menuName = menu === ExpandableMenu.ACCOUNT ? 'Account' : 'Menu';
      trackEvent(menuName + ' Popover Opened');
    }
  };

  useEffect(() => {
    if (menuOpen || hoveringOverHeader) {
      setIsHeaderTransparent(false);
    } else if ($(window).scrollTop() <= 150 && strongerHeaderStyle) {
      setIsHeaderTransparent(true);
    }
  }, [menuOpen, strongerHeaderStyle, hoveringOverHeader]);

  useEffect(() => {
    // Hide menu when any link is clicked.
    $(
      '.headerContent a, .categoryContainer, .gameContainer, .gameLink, .expandableMenu .unclaimedPrizesCard, .accountMenuStatsContainer, .statWithImage, .smallProfileImage, .expandableMenu a, .menuLink, .subSectionLink, .expandableMenu .roundedButton, .covidBlogPostLink, .upgradeCard'
    ).on('click', (e) => {
      // Fixes bug where menu was not hidden after clicking the header link again (desktop).
      if (e.target.classList.contains('desktopHeaderLink')) return;
      handleDismissExpandedMenu();
    });

    setPreviouslyLoggedIn(
      typeof localStorage !== 'undefined' &&
        localStorage.getItem('previously_logged_in') === 'true'
    );
    return () => {
      $(
        '.headerContent a, .categoryContainer, .gameContainer, .gameLink, .expandableMenu .unclaimedPrizesCard, .accountMenuStatsContainer, .statWithImage, .smallProfileImage, .expandableMenu a, .menuLink, .subSectionLink, .expandableMenu .roundedButton, .covidBlogPostLink, .upgradeCard'
      ).off('click');
    };
  }, [selectedMainBurgerMenuContent]);

  useEffect(() => {
    if (strongerHeaderStyle) $('.desktopEnterNowButton').hide(0);

    $(window).scroll(function () {
      if (
        $(this).scrollTop() > 380 ||
        (strongerHeaderStyle && $(this).scrollTop() > 150) ||
        menuOpen
      ) {
        // if (showHeaderCTA) {
        //   $('.barLinks.mobile>.centerBarLinks').css({
        //     width: 'auto',
        //     height: 'auto',
        //     opacity: 1,
        //     pointerEvents: 'auto',
        //   });
        //   $('.barLinks.mobile>.leftBarLinks.withoutName').css({
        //     display: 'flex',
        //   });
        //   $('.barLinks.mobile>.leftBarLinks.withName').css({
        //     display: 'none',
        //   });
        // }
        if (strongerHeaderStyle) {
          $('.desktopEnterNowButton').show(0);
          setIsHeaderTransparent(false);
        }
      } else {
        // $('.barLinks.mobile>.centerBarLinks').css({
        //   width: '1px',
        //   height: '1px',
        //   opacity: 0.01,
        //   pointerEvents: 'none',
        // });

        // $('.barLinks.mobile>.leftBarLinks.withoutName').css({
        //   display: 'none',
        // });
        // $('.barLinks.mobile>.leftBarLinks.withName').css({
        //   display: 'flex',
        // });
        if (strongerHeaderStyle) {
          $('.desktopEnterNowButton').hide(0);
          setIsHeaderTransparent(true);
        }
      }
    });

    // Toggle the transparent header on mouse hover
    if (strongerHeaderStyle) {
      $('.headerContent').on('mouseenter', (e) => {
        setHoveringOverHeader(true);
      });

      $('.headerContent').on('mouseleave', (e) => {
        setHoveringOverHeader(false);
      });
    }
    return () => {
      if (strongerHeaderStyle) {
        $('.headerContent').off('mouseenter');
        $('.headerContent').off('mouseleave');
      }
    };
  }, [router.pathname]);

  const headerBannerToShow = () => {
    if (headerBanner) {
      // HomeWerk redeem entry banner, or referral banner.
      return (
        <HeaderBanner
          bannerContent={headerBanner}
          tappable={headerBanner.tappable}
        />
      );
    }
  };

  const getPrizeFilterOptionForBurgerMenuContent = (burgerMenuContent) => {
    switch (burgerMenuContent) {
      case BurgerMenuContent.TRIPS_AND_STAYS:
        return PrizeFilterOptions.find(
          (option) => option.id === 'trips-and-stays'
        );
      case BurgerMenuContent.EXPERIENCES:
        return PrizeFilterOptions.find((option) => option.id === 'experiences');
      case BurgerMenuContent.LIFESTYLE:
        return PrizeFilterOptions.find(
          (option) => option.id === 'lifestyle-and-beauty'
        );
      case BurgerMenuContent.TECH:
        return PrizeFilterOptions.find((option) => option.id === 'tech');
      case BurgerMenuContent.FAMILY:
        return PrizeFilterOptions.find((option) => option.id === 'family');
    }
  };

  let mainBurgerMenuContent = null;
  switch (selectedMainBurgerMenuContent) {
    case BurgerMenuContent.MAIN_MENU: {
      mainBurgerMenuContent = <MainBurgerMenuContent loggedIn={loggedIn} />;
      break;
    }

    case BurgerMenuContent.ALL_PRIZES: {
      mainBurgerMenuContent = (
        <PrizesBurgerMenuContent
          onSetSelectedMainBurgerMenuContent={setSelectedMainBurgerMenuContent}
          onClickBack={
            expandedMenu === ExpandableMenu.PRIZES
              ? null // if this content is being shown under the PRIZES top-level menu (i.e. not in the burger menu) then don't show back button
              : () => {
                  setSelectedMainBurgerMenuContent(BurgerMenuContent.MAIN_MENU);
                }
          }
        />
      );
      break;
    }

    case BurgerMenuContent.TRIPS_AND_STAYS:
    case BurgerMenuContent.EXPERIENCES:
    case BurgerMenuContent.LIFESTYLE:
    case BurgerMenuContent.TECH:
    case BurgerMenuContent.FAMILY: {
      const prizeFilterOption = getPrizeFilterOptionForBurgerMenuContent(
        selectedMainBurgerMenuContent
      );
      mainBurgerMenuContent = (
        <SubcategoriesBurgerMenuContent
          prizeFilterOption={prizeFilterOption}
          onClickBack={() =>
            setSelectedMainBurgerMenuContent(BurgerMenuContent.ALL_PRIZES)
          }
        />
      );
      break;
    }
  }

  return (
    <>
      <div className="headerContainer">
        {headerBannerToShow()}

        <div className="headerBackground">
          <div className="headerContent">
            <div className="barLinks desktop">
              <div className="leftBarLinks">
                <Link href="/">
                  <a className={'fullLogo'} style={{ textAlign: 'left' }}>
                    <img
                      src={`${ASSET_PREFIX}/images/daymade-logo${
                        isHeaderTransparent ? '-white' : ''
                      }${isChristmas ? '-xmas' : ''}${useHalloweenLogos() ? '-halloween' : ''}.png`}
                      className="logoImage"
                      alt="DAYMADE logo"
                    />
                  </a>
                </Link>
              </div>

              <div className="rightBarLinks">
                {loggedIn ? (
                  <>
                    <Link href="/account/referrals">
                      <a
                        className="desktopHeaderLink"
                        style={{
                          padding: '6px 10px',
                          border: `2px solid ${
                            isHeaderTransparent
                              ? 'white'
                              : colors.primaryBrandGray
                          }`,
                          borderRadius: '17.5px',
                        }}
                      >
                        REFER & EARN £12
                      </a>
                    </Link>

                    <TokenTownHeaderIcon white={isHeaderTransparent} />

                    <div
                      style={{ cursor: 'pointer', position: 'relative' }}
                      onClick={() => {
                        handleExpandMenu(ExpandableMenu.ACCOUNT);
                      }}
                    >
                      {expandedMenu === ExpandableMenu.ACCOUNT ? (
                        <HeaderIconWithBadge
                          iconImage={`${ASSET_PREFIX}/images/header/close-menu-icon${
                            isHeaderTransparent ? '-white' : ''
                          }.svg`}
                          iconImageAlt="close menu"
                        />
                      ) : (
                        <AccountHeaderIcon white={isHeaderTransparent} />
                      )}
                    </div>

                    <div
                      style={{ cursor: 'pointer', position: 'relative' }}
                      onClick={() => {
                        handleExpandMenu(ExpandableMenu.MAIN_BURGER);
                      }}
                    >
                      <HeaderIconWithBadge
                        iconImage={`${ASSET_PREFIX}/images/header/${
                          expandedMenu === ExpandableMenu.MAIN_BURGER
                            ? 'close-menu-icon'
                            : 'main-burger-menu-icon'
                        }${isHeaderTransparent ? '-white' : ''}.svg`}
                        iconImageAlt={
                          expandedMenu === ExpandableMenu.MAIN_BURGER
                            ? 'close menu'
                            : 'open main menu'
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <Link href="/how-it-works">
                      <a className="desktopHeaderLink">How It Works</a>
                    </Link>

                    <Link href="/prizes">
                      <a className="desktopHeaderLink">Prizes</a>
                    </Link>

                    <Link href="/winners">
                      <a className="desktopHeaderLink">Winners</a>
                    </Link>

                    <Link href="/giving-back">
                      <a className="desktopHeaderLink">Plant Trees</a>
                    </Link>

                    {strongerHeaderStyle && (
                      <Link href="/faq">
                        <a className="desktopHeaderLink">FAQ</a>
                      </Link>
                    )}

                    <TokenTownHeaderIcon white={isHeaderTransparent} />

                    {loggedIn && (
                      <div
                        style={{
                          cursor: 'pointer',
                          position: 'relative',
                          width: 40,
                        }}
                        onClick={() => {
                          handleExpandMenu(ExpandableMenu.ACCOUNT);
                        }}
                      >
                        {expandedMenu === ExpandableMenu.ACCOUNT ? (
                          <HeaderIconWithBadge
                            iconImage={`${ASSET_PREFIX}/images/header/close-menu-icon${
                              isHeaderTransparent ? '-white' : ''
                            }.svg`}
                            iconImageAlt="close menu"
                          />
                        ) : (
                          <AccountHeaderIcon white={isHeaderTransparent} />
                        )}
                      </div>
                    )}

                    {previouslyLoggedIn && (
                      <div
                        style={{ cursor: 'pointer', position: 'relative' }}
                        onClick={() => {
                          handleExpandMenu(ExpandableMenu.MAIN_BURGER);
                        }}
                      >
                        <HeaderIconWithBadge
                          iconImage={`${ASSET_PREFIX}/images/header/${
                            expandedMenu === ExpandableMenu.MAIN_BURGER
                              ? 'close-menu-icon'
                              : 'main-burger-menu-icon'
                          }${isHeaderTransparent ? '-white' : ''}.svg`}
                          iconImageAlt={
                            expandedMenu === ExpandableMenu.MAIN_BURGER
                              ? 'close menu'
                              : 'open main menu'
                          }
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="barLinks mobile">
              <div className="leftBarLinks flex">
                <Link href="/">
                  <a
                    style={{ flexGrow: 1 }}
                    className={showHeaderCTA ? 'fullLogo' : ''}
                  >
                    <img
                      id="mobileLogo"
                      src={`${ASSET_PREFIX}/images/daymade-logo${isHeaderTransparent ? '-white' : ''}${isChristmas ? '-xmas' : ''}${useHalloweenLogos() ? '-halloween' : ''}.png`}
                      className="logoImage"
                      alt="DAYMADE logo"
                    />
                  </a>
                </Link>
              </div>

              <div className="rightBarLinks" style={{ paddingLeft: '15px' }}>
                <TokenTownHeaderIcon white={isHeaderTransparent} />

                {loggedIn && (
                  <div
                    style={{ cursor: 'pointer', position: 'relative' }}
                    onClick={() => {
                      handleExpandMenu(ExpandableMenu.ACCOUNT);
                    }}
                  >
                    {expandedMenu === ExpandableMenu.ACCOUNT ? (
                      <HeaderIconWithBadge
                        iconImage={`${ASSET_PREFIX}/images/header/close-menu-icon${
                          isHeaderTransparent ? '-white' : ''
                        }.svg`}
                        iconImageAlt="close menu"
                      />
                    ) : (
                      <AccountHeaderIcon white={isHeaderTransparent} />
                    )}
                  </div>
                )}

                {((showDrawCounter && !isMobile) || !showDrawCounter) && (
                  <div
                    style={{ cursor: 'pointer', position: 'relative' }}
                    onClick={() => {
                      handleExpandMenu(ExpandableMenu.MAIN_BURGER);
                    }}
                  >
                    <HeaderIconWithBadge
                      iconImage={`${ASSET_PREFIX}/images/header/${
                        expandedMenu === ExpandableMenu.MAIN_BURGER
                          ? 'close-menu-icon'
                          : 'main-burger-menu-icon'
                      }${isHeaderTransparent ? '-white' : ''}.svg`}
                      iconImageAlt={
                        expandedMenu === ExpandableMenu.MAIN_BURGER
                          ? 'close menu'
                          : 'open main menu'
                      }
                    />
                  </div>
                )}

                {showDrawCounter && isMobile && !loggedIn && (
                  <PlasmicComponent
                    forceOriginal
                    component="Draw Countdown"
                    componentProps={{
                      targetTimeUtc: upcomingDrawDate,
                      variant: 'variant2',
                    }}
                  />
                )}
              </div>
            </div>
          </div>

          {isHeaderTransparent ? null : <GlowBar color="rgba(0, 0, 0, 0.1)" />}
        </div>

        <div
          className={
            expandedMenu === ExpandableMenu.MAIN_BURGER
              ? 'expandableMenu'
              : 'expandableMenu hidden'
          }
        >
          <ScrollableComponent
            isOpen={expandedMenu === ExpandableMenu.MAIN_BURGER}
            maxHeight={'max-h-[80vh] '}
          >
            <Separator
              style={{
                width: '98%',
                margin: 0,
                height: '2px',
              }}
            />

            {mainBurgerMenuContent}

            {!challengerPage && !useDefaultCTA && (
              <RoundedButton
                onClick={enterCtaHandler}
                colorStyle={enterCtaColorStyle}
                className="enterButton hideOnWideWidth"
              >
                Enter Now
              </RoundedButton>
            )}

            {!challengerPage && useDefaultCTA && defaultCTA && (
              <RoundedButton
                onClick={() => Router.push(defaultCTA.url)}
                colorStyle={enterCtaColorStyle}
                className="enterButton hideOnWideWidth"
              >
                {defaultCTA.label}
              </RoundedButton>
            )}

            <SocialMediaLinks />
          </ScrollableComponent>
        </div>

        <div
          className={
            expandedMenu === ExpandableMenu.ACCOUNT
              ? 'expandableMenu rightAligned'
              : 'expandableMenu rightAligned hidden'
          }
        >
          <ScrollableComponent
            isOpen={expandedMenu === ExpandableMenu.ACCOUNT}
            maxHeight={'max-h-[80vh] '}
          >
            {!loggedIn ? (
              <div className="flexCentered">
                <Separator
                  style={{
                    width: '98%',
                    margin: '0 0 1em',
                    height: '2px',
                  }}
                />
                <div className="flexLeftAligned fullWidth">
                  <Link href="/login">
                    <a className="menuLink">Sign in</a>
                  </Link>

                  <Link href="/register">
                    <a className="menuLink">Register</a>
                  </Link>
                </div>
              </div>
            ) : (
              <AccountMenu
                isOpen={expandedMenu === ExpandableMenu.ACCOUNT}
                tickets={upcomingDrawTickets}
                currentUser={currentUser}
                welcomeBackPlanName={welcomeBackPlanName}
                handleNavigate={() => {
                  setExpandedMenu(null);
                }}
                signOutOnClick={() => {
                  logout();
                  handleDismissExpandedMenu();
                  displayToast({
                    title: 'You have successfully logged out.',
                    timeout: 3500,
                  });
                  router.replace('/')
                }}
              />
            )}
            {!challengerPage && !useDefaultCTA && (
              <RoundedButton
                onClick={enterCtaHandler}
                className="enterButton hideOnWideWidth"
                colorStyle={enterCtaColorStyle}
              >
                Enter Now
              </RoundedButton>
            )}
            {!challengerPage && useDefaultCTA && defaultCTA && (
              <RoundedButton
                onClick={() => Router.push(defaultCTA.url)}
                colorStyle={enterCtaColorStyle}
                className="enterButton hideOnWideWidth"
              >
                {defaultCTA.label}
              </RoundedButton>
            )}
            <SocialMediaLinks />
          </ScrollableComponent>
        </div>

        {/* <div
          className={
            expandedMenu === ExpandableMenu.SEARCH
              ? 'searchPanel expandableMenu rightAligned'
              : 'expandableMenu rightAligned hidden'
          }
        >
          <Separator
            style={{
              width: '100%',
              margin: 0,
              height: '2px',
            }}
          />

          <SearchPanel
            open={expandedMenu === ExpandableMenu.SEARCH}
            closePanel={handleDismissExpandedMenu}
          />
        </div> */}

        <style jsx>{`
          .headerContainer {
            width: 100%;
            position: fixed;
            height: ${HEADER_HEIGHT};
            z-index: 999;
          }

          :global(.headerContainer a) {
            text-decoration: none !important;
          }

          .headerBackground {
            width: 100%;
            background-color: ${isHeaderTransparent ? 'transparent' : 'white'};
          }

          .headerBackground.withButton {
            background-color: white;
          }

          .headerContent {
            width: 100%;
            max-width: 2000px;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            padding: 12px 16px;
            font-weight: 600;
            height: 100%;
          }

          .leftBarLinks,
          .centerBarLinks,
          .rightBarLinks {
            display: flex;
            flex: 1;
            align-items: center;
          }

          .leftBarLinks {
            justify-content: flex-start;
          }

          .centerBarLinks {
            justify-content: center;
            pointer-events: auto;
          }

          .rightBarLinks {
            justify-content: flex-end;
          }

          .barLinks.desktop {
            display: flex;
            min-height: 66px;
          }

          .barLinks.mobile {
            display: none;
          }

          @media (max-width: ${simplifiedDesktopHeader
              ? /* burger menu on enter/home page to reduce distractions */ '3000px'
              : `${HOME_PAGE_BREAK_POINT}px`}) {
            .barLinks.desktop {
              display: none;
            }

            .barLinks.mobile {
              display: flex;
              min-height: 59px;
            }
          }

          .hiddenOnMobile {
            display: none;
          }

          @media (min-width: ${MOBILE_WIDTH.toString()}px) {
            .hideDesktop {
              display: none;
            }
          }

          @media (max-width: ${MOBILE_WIDTH.toString()}px) {
            .hideDesktop {
              display: flex;
            }
          }

          @media (min-width: ${simplifiedDesktopHeader
              ? /* burger menu on enter/home page to reduce distractions */ '3000px'
              : `${HOME_PAGE_BREAK_POINT}px`}) {
            .menuLink.hideOnWideWidth,
            :global(.roundedButton.enterButton.hideOnWideWidth) {
              display: none;
            }

            .hiddenOnMobile {
              display: none;
            }
          }

          .barLinks.mobile > .centerBarLinks {
            opacity: 0.01;
            width: 1px;
            height: 1px;
            pointer-events: none;
          }

          .desktopHeaderLink {
            cursor: pointer;
            font-size: 1.1em;
            margin-right: 2em;
            transition: 0.3s;
            color: ${isHeaderTransparent ? 'white' : colors.primaryBrandGray};
            text-transform: uppercase;
          }

          .desktopHeaderLink.highlighted {
            text-shadow: 0 0 5px ${colors.brand.neon};
            transition: 0.3s;
          }

          .expandableMenu {
            position: absolute;
            display: block;
            background-color: white;
            max-height: 80vh;
            overflow-y: auto;
            align-self: center;
            box-shadow: 0 4px 4px 1px rgba(55, 55, 55, 0.4);
            border-radius: 0 0 8px 8px;
            width: 100%;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            right: 0;
            font-size: 15px;
          }

          @media (min-width: 600px) {
            .expandableMenu {
              position: absolute;
              max-width: 450px;
            }
          }

          .expandableMenu,
          .expandableMenu > * {
            flex-shrink: 0;
            width: 100%;
          }

          .covidBlogPostLink {
            background-color: #e1e1e1;
            color: ${colors.primaryBrandGray};
            font-style: italic;
            font-weight: 700;
            cursor: pointer;
            width: 98%;
            flex-shrink: 0;
            padding: 1em;
            text-align: center;
            border-radius: 4px;
            margin-bottom: 1em;
            display: flex;
            justify-content: center;
          }

          .covidBlogPostLink:hover {
            background-color: #cdcdcd;
          }

          :global(.menuLink) {
            cursor: pointer;
            display: inline-block;
            font-family: 'Montserrat', sans-serif !important;
            font-weight: 900;
            letter-spacing: 0;
            padding-left: 1em;
            text-align: left;
            margin: 0.7em 0;
            font-size: 15px;
          }

          :global(.menuLink.nonbold) {
            font-weight: 600;
          }

          :global(.subSectionLink) {
            text-transform: none;
            font-style: italic;
            font-weight: 600;
            padding-left: 1em;
          }

          :global(.menuLink:hover),
          :global(.subSectionLink:hover) {
            color: black;
          }

          a {
            text-align: left;
            margin: 0;
            padding: 0.5em;
            border-radius: 4px;
          }

          .logoImage {
            height: 40px;
            width: auto;
            cursor: pointer;
            vertical-align: middle;
          }

          // Attempts to solve blurry logo issue Abbi experiences
          @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
            .logoImage {
              image-rendering: -webkit-optimize-contrast !important;
            }
          }

          /* Unset for Safari 11+ */
          @media not all and (min-resolution: 0.001dpcm) {
            @supports (-webkit-appearance: none) and (stroke-color: transparent) {
              .logoImage {
                image-rendering: unset !important;
              }
            }
          }

          .fullLogo.hidden {
            display: none;
          }

          .noTextLogo.hidden {
            display: none;
          }

          :global(.enterDrawButton.hidden) {
            display: none !important;
          }

          :global(.headerIcon) {
            color: #4a4a4a;
          }

          :global(.headerIcon:hover) {
            cursor: pointer;
            color: black;
            transition: 0.2s;
          }

          .headerContent {
            flex-direction: column;
          }

          .barLinks {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .expandableMenu {
            width: 100%;
            flex-direction: column;
            justify-content: center;
            align-content: center;
            right: 0;
          }

          .hidden {
            display: none;
          }

          .centralLinks :global(a),
          .expandableMenu :global(a) {
            width: 100%;
          }

          :global(.roundedButton.enterButton) {
            display: block !important;
            margin: 1.5em auto;
            max-width: 300px;
            width: 100%;
          }

          .referralsLink {
            display: block;
          }

          :global(.nextWinnerBanner.hidden) {
            opacity: 0;
            transform: scale(0.9);
            transition: 0.2s;
          }

          .accountMenu {
            padding: 0 1em;
          }

          :global(.accountMenu .upgradeCard) {
            margin: 0.5em 0;
          }

          :global(.headerIconWithBadge) {
            margin: 0 0.5em;
          }

          @media (min-width: 600px) {
            .accountMenu,
            .searchPanel {
              max-width: 450px;
            }
          }
        `}</style>
      </div>

      <div className="contentPadder" style={{ height: HEADER_HEIGHT }} />

      {expandedMenu !== null && (
        <div
          id="dimmedBackground"
          onClick={handleDismissExpandedMenu}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(55,55,55,0.3)',
            zIndex: 4,
          }}
        />
      )}
    </>
  );
});

export default Header;
