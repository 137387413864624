import '@adyen/adyen-web/dist/adyen.css';
import { runningInProduction } from 'utils/constants';
import { adyenClientKey } from '../../private/adyen';
import { useEffect, useRef, useState } from 'react';
import { getCookie } from 'utils/cookie';
import {
  createApplePayMerchantSession,
  getAdyenPaymentDetails,
  getAdyenPaymentRefusalReason,
  makeAdyenPaymentRequest,
} from 'api/payments';
import PlainLoadingSpinner from 'components/PlainLoadingSpinner';
import { useUserStore } from 'hooks/stores/useUserStore';
import { sleep } from 'utils/common';

// `redirectResult` is an optional parameter that is only supplied when this component is displayed on the /validate-3ds-payment page.
const AdyenDropInCheckout = ({
  queryParamsObject = {} /* used by validate-3ds-payment page */,
  paymentSession,
  initialRedirectResult,
  displayError,
  completionHandler = () => {},
  onLoadingStateChange,
  style,
}) => {
  const dropInContainer = useRef();
  const adyenComponent = useRef();
  const { outsideUK } = useUserStore();

  const [loadingForm, setLoadingForm] = useState(true);

  const [redirectResult, setRedirectResult] = useState(initialRedirectResult);

  const latestRefusalReason = useRef(null);

  const refusalReasonTimerRef = useRef(null);

  const handleSuccessfulPayment = () => {
    displayError(null);

    // Show loading spinner while we wait for the AUTHORISATION webhook to hit the API.
    onLoadingStateChange(true);

    // This does not advance to confirmation page, but it does fire off some GA EEC tracking events.
    completionHandler();
  };

  // Periodically calls API to check for failure reason.
  // This is necessary because the payment result is received asynchronously via a webhook.
  const attemptToFetchRefusalReason = async () => {
    const { adyenPaymentSessionId } = paymentSession;
    const jwt = getCookie('jwt') || getCookie('guest_jwt');
    return (await getAdyenPaymentRefusalReason(jwt, adyenPaymentSessionId))
      .failureReason;
  };

  const handleUnsuccessfulPayment = async (optionalRefusualReason) => {

    let refusalReason =
      optionalRefusualReason || (await attemptToFetchRefusalReason());
    console.log('refusalReason', refusalReason);

    if (refusalReason?.toUpperCase().includes('MCC')) {
      refusalReason = `${refusalReason}: this is likely due to you using a credit (rather than debit) card`;
    }

    // Clear timeout in case there is one already running (e.g. after someone has already had a failed payment).
    if (refusalReasonTimerRef.current) {
      clearTimeout(refusalReasonTimerRef.current);
    }

    if (refusalReason) {
      if (refusalReason !== latestRefusalReason.current) {
        latestRefusalReason.current = refusalReason;
        displayErrorWithOptionalRefusualReason(refusalReason);
      }
      // Check again in 2 second in case an updated webhook comes through e.g. for a subsequent failed payment.
      refusalReasonTimerRef.current = setTimeout(
        handleUnsuccessfulPayment,
        2000
      );
    } else {
      refusalReason = 'GENERIC';

      if (refusalReason !== latestRefusalReason.current) {
        latestRefusalReason.current = 'GENERIC';
        displayErrorWithOptionalRefusualReason();
      }
      // Try again in 1 second (maybe webhook will have come through by then).
      refusalReasonTimerRef.current = setTimeout(
        handleUnsuccessfulPayment,
        1000
      );
    }
  };

  const displayErrorWithOptionalRefusualReason = (refusalReason) => {
    if (refusalReason) {
      displayError(
        `Your payment didn't go through (${refusalReason}). Please note that we only accept UK debit MasterCard and Visa cards. Please try again, or drop us an email if you need any help: team@daymade.co.uk.`
      );
    } else {
      displayError(
        "Your payment didn't go through. Please note that we only accept UK debit MasterCard and Visa cards. Please try again, or drop us an email if you need any help: team@daymade.co.uk."
      );
    }
  };

  const initialiseCheckout = async () => {
    if (outsideUK) {
      return;
    }
    const applePayConfiguration = {
      buttonType: 'plain',
      onValidateMerchant: (resolve, reject, validationURL) => {
        // Your server uses the validation URL to request a session from the Apple Pay server.
        // Call resolve(MERCHANTSESSION) or reject() to complete merchant validation.
        createApplePayMerchantSession(validationURL)
          .then((response) => {
            // Complete merchant validation with resolve(MERCHANTSESSION) after receiving an opaque merchant session object, MerchantSession
            resolve(response);
          })
          .catch((error) => {
            // Complete merchant validation with reject() if any error occurs
            reject();
            throw error;
          });
      },
    };

    const googlePayConfiguration = {
      configuration: {
        merchantName: 'DAYMADE',
        gatewayMerchantId: 'TripHuntersCOM',
        merchantId: 'BCR2DN6TQ6ON3QJK',
      },
      allowCreditCards: false,
    };

    const configuration = {
      environment: runningInProduction ? 'live' : 'test',
      session: {
        id: paymentSession.id,
        sessionData: paymentSession.sessionData,
      },
      clientKey: runningInProduction
        ? adyenClientKey.live
        : adyenClientKey.test,
      onSubmit: async (state, component) => {
        displayError(null);
        component.setState('loading');
        const queryParamString = Object.keys(queryParamsObject)
          .map((key) => `${key}=${queryParamsObject[key]}`)
          .join('&');
        const jwt = getCookie('jwt') || getCookie('guest_jwt');
        const response = await makeAdyenPaymentRequest(
          jwt,
          paymentSession.adyenPaymentSessionId,
          paymentSession.id,
          state,
          queryParamString
        );
        component.setState('ready');
        onLoadingStateChange(false);
        if (response.action) {
          component.handleAction(response.action);
        } else if (response.resultCode === 'Authorised') {
          console.log('Successful payment ✅ ');
          return handleSuccessfulPayment();
        } else if (response.resultCode === 'Refused') {
          console.error('Unsuccessful payment ❌');
          await handleUnsuccessfulPayment(response.refusalReason);

          // Clear this so the form can render properly to take payment again
          setRedirectResult(null);

          // Wait for the state update
          await sleep(200);

          // Display payment form again
          if (!redirectResult) {
            component.unmount();
            initialiseCheckout();
          }
        }
      },
      onAdditionalDetails: async (state, component) => {
        const result = await getAdyenPaymentDetails(getCookie('jwt'), state);
        if (result.resultCode === 'Refused') {
          console.error('Unsuccessful payment ❌');
          await handleUnsuccessfulPayment(result.refusalReason);

          // Clear this so the form can render properly to take payment again
          setRedirectResult(null);

          // Wait for the state update
          await sleep(200);

          // Display payment form again
          if (!redirectResult) {
            component.unmount();
            initialiseCheckout();
          }
        }
      },
      onPaymentCompleted: async (result, component) => {
        displayError(null);

        switch (result.resultCode) {
          case 'Authorised':
            console.log('Successful payment ✅ ');
            return handleSuccessfulPayment();

          case 'Refused':
          default: {
            console.error('Unsuccessful payment ❌');
            await handleUnsuccessfulPayment();

            // Clear this so the form can render properly to take payment again
            setRedirectResult(null);

            // Wait for the state update
            await sleep(200);

            // Display payment form again
            if (!redirectResult) {
              component.unmount();
              initialiseCheckout();
            }
          }
        }
      },
      onError: (error) => {
        console.log(
          'Error caught by AdyenDropInCheckout',
          error,
          JSON.stringify(error)
        );
        throw error;
      },
      paymentMethodsConfiguration: {
        card: {
          name: 'Debit Card',
        },
        applepay: applePayConfiguration,
        paywithgoogle: googlePayConfiguration,
      },
    };

    const AdyenCheckout = (await import('@adyen/adyen-web')).default;
    const checkout = await AdyenCheckout(configuration);

    // Filter out saved Apply Pay payment methods as these cannot be used in the drop-in payment form.
    checkout.paymentMethodsResponse.storedPaymentMethods =
      checkout.paymentMethodsResponse.storedPaymentMethods.filter(
        (storedPaymentMethod) => !storedPaymentMethod.brand.includes('applepay')
      );
    if (redirectResult) {
      checkout.submitDetails({ details: { redirectResult } });
    } else {
      if (dropInContainer.current) {
        adyenComponent.current = checkout
          .create('dropin', {
            instantPaymentTypes: ['applepay', 'paywithgoogle'],
          })
          .mount(dropInContainer.current);
      }
    }
    setLoadingForm(false);
  };

  useEffect(initialiseCheckout, [redirectResult, paymentSession]);

  useEffect(() => {
    return () => {
      if (refusalReasonTimerRef.current) {
        clearTimeout(refusalReasonTimerRef.current);
      }

      if (adyenComponent.current) {
        adyenComponent.current.unmount();
      }
    };
  }, []);
  if (outsideUK) {
    return (
      <div className="w-full h-full flex flex-col justify-center">
        <h3
          style={{
            fontWeight: 800,
            letterSpacing: 'normal',
            textShadow: '2px 2px white',
          }}
        >
          It looks like you are visiting us from outside the United Kingdom
        </h3>
        <p>
          Please note that DAYMADE operates exclusively in the UK and is
          available to UK residents only. We hope to bring the DAYMADE
          experience to your country one day!
        </p>
      </div>
    );
  }
  return (
    <div style={style}>
      {loadingForm && <PlainLoadingSpinner style={{ margin: '2em auto' }} />}

      <div ref={dropInContainer} />

      <p
        style={{
          fontWeight: 500,
          fontStyle: 'italic',
          color: '#777',
          margin: '1em auto',
          textAlign: 'center',
        }}
      >
        (Note: Credit Cards not accepted)
      </p>

      <style jsx>
        {`
          :global(.adyen-checkout__applepay__button) {
            -webkit-appearance: -apple-pay-button !important;
          }

          :global(.adyen-checkout__payment-method__header__title:hover) {
            background: unset;
          }

          :global(.adyen-checkout__button:hover .adyen-checkout__button__text) {
            color: #ccc;
          }

          :global(.adyen-checkout__error-text) {
            font-size: 1em;
          }

          :global(.adyen-checkout__button--pay) {
            font-weight: bold;
          }
        `}
      </style>
    </div>
  );
};

export default AdyenDropInCheckout;
