import { performFetchRequest } from 'api/common';
import { runningLocally } from 'utils/constants';

export const fetchActivityFeedItems = async (lastEventId) => {
  if (runningLocally) return await performFetchRequest(
    `/activity-feed/fetch-feed-items-dev${lastEventId ? `?lastEventId=${lastEventId}` : ''}`
  );
  return await performFetchRequest(
    `/activity-feed/fetch-feed-items${lastEventId ? `?lastEventId=${lastEventId}` : ''}`
  );
};
